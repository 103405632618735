import * as React from "react";
import * as style from './index.module.scss';
import {withPrismicPreviewResolver} from "gatsby-plugin-prismic-previews";
import Metadata from "../templates/HomePage";
import {graphql} from "gatsby";
import {PrismicRichText} from "@prismicio/react";

const TermsPage = ({data}) => {


    const pageData = data?.prismicTermsConditions.dataRaw;
    // const pageData = data?.prismicHomePage.dataRaw;

    return (
        <>
            <section className="section-center">
                <div className={style.top}>
                    <PrismicRichText field={pageData.content}/>
                </div>
            </section>
        </>
    );
};

export default withPrismicPreviewResolver(TermsPage);


export const Head = ({data}) => {
    return <Metadata pageData={data?.prismicTermsConditions?.dataRaw}/>;
};

export const query = graphql`
  query termsConditionsQuery($id: String) {
    prismicTermsConditions(id: { eq: $id }) {
      _previewable
      dataRaw
    }
  }
`;

